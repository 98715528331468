/**
 * src/components/Characters/index.jsx
 * create and display characters
 *
 * created by Lynchee on 7/16/23
 */

// Characters
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import './style.css';

const Characters = ({
  isMobile,
  characterGroups,
  selectedCharacter,
  setSelectedCharacter,
  isPlaying,
  characterConfirmed,
}) => {
  const handleCharacterSelection = character => {
    setSelectedCharacter(character);
  };
  console.log('characterGroups>>>>>', characterGroups)
  return (
    <Grid
      container
      spacing={2}
      sx={{ marginBottom: 5, px: '24px' }}
      className='main-container'
    >
      {characterGroups.map(
        (character, index) =>
        (
          <Grid item xs={isMobile ? 12 : 6} md={4} lg={12 / 5} key={index}>
            <Button
              variant='outlined'

              onClick={() => handleCharacterSelection(character)}
              sx={{
                width: '100%',
                backgroundColor:
                  selectedCharacter &&
                    character.character_id === selectedCharacter.character_id
                    ? '#35394A'
                    : '',
                borderColor:
                  selectedCharacter &&
                    character.character_id === selectedCharacter.character_id
                    ? '#A7BFFF'
                    : '#adadad',
                boderOpacity: '0.020',
                borderRadius: '16px',
                '&:hover': {
                  backgroundColor: '#35394A',
                  borderColor: '#617CC2',
                },
                padding: '27px 0',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Avatar
                variant="rounded"
                alt={character.name}
                src={character.image_url} sx={{ margin: 'auto', minWidth: '210px', minHeight: '186px', marginBottom: '5px' }}
              />
              {/* <Avatar
                  alt={character.name}
                  src={character.image_url}
                  sx={{ margin: auto }}
                /> */}
              <Typography
                variant='body1'
                sx={{
                  color: 'white',
                  fontFamily: 'Prompt, sans-serif',
                  marginTop: '0.5rem',
                  fontSize: '14px',
                }}
              >
                {character.name}
              </Typography>
            </Button>
          </Grid>
        )
      )}
    </Grid>
  );
};

export default Characters;
