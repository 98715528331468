/**
 * src/pages/Home.jsx
 *
 * created by Lynchee on 7/28/23
 */

import React, { useState, useRef, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import lz from 'lz-string';
import logo from '../assets/svgs/logo2.svg';
import settings from '../assets/svgs/settings.svg';
import arrowBack from '../assets/svgs/arrow-left.svg';
import Characters from '../components/Characters';
import Button from '@mui/material/Button';
import Header from '../components/Header';
import SignIn from '../components/Auth/SignIn';
import SignOut from '../components/Auth/SignOut';
import { Box } from '@mui/material';

const Home = ({
  isMobile,
  selectedCharacter,
  setSelectedCharacter,
  isPlaying,
  characterGroups,
  setCharacterGroups,
  setCharacterConfirmed,
  characterConfirmed,
  token,
  setToken,
  isLoggedIn,
  user,
  handleDisconnect
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  // Get host
  const host = process.env.REACT_APP_API_HOST
  // Get characters
  useEffect(() => {
    setLoading(true);
    const url = `${host}/characters`;
    let headers = {
      'Content-Type': 'application/json',
    };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    fetch(url, {
      method: 'GET',
      headers: headers,
    })
      .then(response => response.json())
      .then(data => {
        setCharacterGroups(data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.error(err);
      });
  }, [setCharacterGroups, token]);

  const handleNextClick = () => {
    setCharacterConfirmed(true);
    const compressedCharacter = lz.compressToEncodedURIComponent(
      JSON.stringify(selectedCharacter)
    );
    navigate('/settings?character=' + compressedCharacter);
  };

  return (
    <div className='home'>
      {loading ? (
        <div style={{ height: '100vh', width: '100%', display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div className="sound-wave"><span></span><span></span><span></span><span></span><span></span><span></span></div>
        </div>
      ) : (
        <>
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '100px' }}>
            <Navigate to="/" replace={true} >
              <img src={logo} alt='Logo' />
            </Navigate>
          </Box>

          {/* <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '74px' }}>
            <Button sx={{ color: '#FFF', display: 'flex', alignItems: 'center', gap: '23px', cursor: 'pointer' }}>
              <img src={arrowBack} alt='Logo' />
              <p className='back-button'>
                Back
              </p>
            </Button>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img style={{ marginRight: "8px" }} src={settings} alt='settings' />
              {user ? (
                <SignOut
                  isLoggedIn={isLoggedIn}
                  user={user}
                  handleDisconnect={handleDisconnect}
                />
              ) : (
                <SignIn isLoggedIn={isLoggedIn} setToken={setToken} />
              )}
            </Box>
          </Box> */}

          <p className='header '>Select a character</p>

          <Characters
            isMobile={isMobile}
            characterGroups={characterGroups}
            selectedCharacter={selectedCharacter}
            setSelectedCharacter={setSelectedCharacter}
            isPlaying={isPlaying}
            characterConfirmed={characterConfirmed}
          />

          <Button
            variant='contained'
            onClick={handleNextClick}
            fullWidth
            size='large'
            disabled={!selectedCharacter}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '6px',
              backgroundColor: 'transparent',
              border: '1px solid #fff',
              width: "auto",
              marginBottom: '30px',
              '&.Mui-disabled': {
                backgroundColor: 'transparent',
                opacity: 0.5,
              },
              textTransform: 'none',
            }}
          >
            <span>Next</span>
            <svg width={17} height={18} viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.31104 14.61L10.9294 9.99168C11.4748 9.44626 11.4748 8.55376 10.9294 8.00835L6.31104 3.39001" stroke="white" strokeWidth="1.5" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Button>
        </>
      )}
    </div>
  );
};

export default Home;
